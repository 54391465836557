<template>
  <b-card no-body>
    <b-card-header class="pb-0"> </b-card-header>
    <b-card-body>
      <div class="shadow w-100 p-2 mb-2 rounded" v-b-toggle.answer-collapse>
        <label for="question-text">نص السؤال</label>
        <b-form-textarea
          id="question-text"
          size="sm"
          v-model="questionBankForm.question"
        ></b-form-textarea>
      </div>
      <b-collapse id="answer-collapse" class="shadow mb-2 p-2">
        <b-list-group>
          <b-list-group-item
            class="shadow border-0 mb-2 d-flex pr-0"
            v-for="(ans, index) in questionBankForm.answers"
            :key="index"
          >
            <b-form-radio
              v-model="questionBankForm.rightAnswer"
              :name="'_' + questionBankForm.id"
              :value="index + 1"
            >
            </b-form-radio>
            <b-form-textarea size="sm" v-model="ans.answer"></b-form-textarea>
            <b-button
              size="sm"
              class="ml-auto"
              variant="link"
              @click="removeQuestionAnswer(index)"
            >
              <unicon name="trash-alt" fill="#FF6330"></unicon>
            </b-button>
          </b-list-group-item>
          <b-button
            block
            v-if="questionBankForm.answers.length < 5"
            size="sm"
            variant="primary"
            class="ml-auto"
            @click="addQuestionAnswer"
          >
            <unicon name="plus" fill="#fff"></unicon>
          </b-button>
        </b-list-group>
      </b-collapse>
      <b-form-group label="تابع للفيديو - الملف" label-for="QuestionForVideo">
        <v-select
          id="QuestionForVideo"
          v-model="questionBankForm.videoId"
          label="name"
          :dir="$store.state.appConfig.isRTL ? 'ltr' : 'rtl'"
          :options="videosList"
          :reduce="(item) => item.id"
        ></v-select>
      </b-form-group>
      <b-form-group label="تاريخ النشر" label-for="created-date">
        <b-form-input
          id="created-date"
          v-model="questionBankForm.dateCreated"
          readonly
        ></b-form-input>
      </b-form-group>
    </b-card-body>
    <b-card-footer>
      <b-button
        variant="primary"
        :disabled="!questionBankForm.rightAnswer"
        @click="setQuestionBank(questionBankForm)"
      >
        {{ !+id ? "حفظ" : "تعديل" }}
      </b-button>
      <b-button variant="outline-primary" class="ml-1" to="/question-bank">
        عودة
      </b-button>
      <b-button
        @click.prevent="RemoveQuestionBanks()"
        size="md"
        class="ml-1"
        variant="danger"
      >
        حذف
      </b-button>
    </b-card-footer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import store from "@/store";
import vSelect from "vue-select";
import Swal from "sweetalert2";

import {
  VBToggle,
  BCard,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BListGroup,
  BListGroupItem,
  BButton,
  BFormRadio,
  BFormTextarea,
  BFormGroup,
  BFormInput,
  BCollapse,
} from "bootstrap-vue";
export default {
  components: {
    vSelect,
    BCollapse,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BListGroup,
    BListGroupItem,
    BButton,
    BFormRadio,
    BFormTextarea,
    BFormGroup,
    BFormInput,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    id: String,
  },
  created() {
    store.commit("app/UPDATE_CREATE_BUTTON_AND_GLOBALE_SEARCH", {
      isActive: false,
      text: "سؤال جديد",
      fetchingFunction: this.openQuestionBank,
      placeHolder: "ابحث عن سؤال محدد",
      value: "",
    });
    this.fetchQuestionBankDetails(+this.id);
  },
  computed: {
    ...mapGetters(["questionBankForm", "videosList"]),
  },
  methods: {
    ...mapActions([
      "fetchQuestionBankDetails",
      "addQuestionAnswer",
      "removeQuestionAnswer",
      "setQuestionBank",
      "removeQuestionBank"
    ]),
    RemoveQuestionBanks() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: true,
      });
      swalWithBootstrapButtons
        .fire({
          title: "هل أنت متأكد؟",
          text: "هل فعلاً تريد حذف السؤال ؟",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "نعم!",
          cancelButtonText: "إلغاء",
          reverseButtons: false,
        })
        .then((result) => {
          if (result.value) {
            console.log(this.questionBankForm)
            this.removeQuestionBank([this.questionBankForm.id]);
          }
        });
    },
  },
};
</script>